// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-peru-tsx": () => import("./../src/pages/peru.tsx" /* webpackChunkName: "component---src-pages-peru-tsx" */),
  "component---src-pages-typ-tsx": () => import("./../src/pages/typ.tsx" /* webpackChunkName: "component---src-pages-typ-tsx" */),
  "component---src-pages-uruguay-tsx": () => import("./../src/pages/uruguay.tsx" /* webpackChunkName: "component---src-pages-uruguay-tsx" */)
}

